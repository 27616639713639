import { H3, theme, IconCaret } from "@upsolve/ui";
import React, { ReactNode, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import ThumbsUp from "./images/thumbs-up.svg";
import ThumbsDown from "./images/thumbs-down.svg";
import QuestionMark from "./QuestionMark.svg";
import { EligibilityResult, getStatusQuoCompoundInterest, getStatusQuoRepaymentInfo } from "./eligibilityFunctions";
import { DetailCard } from "./DetailCard";
import { formatMonths } from "./formatMonths";
import { camelCase } from "lodash";
import { Tooltip } from "./Tooltip";
import { DebtAdvisorChat } from "./DebtAdvisorChat";
import { Button } from "./Components";
import { addMonths, format } from "date-fns";

//TODO: possibly consolidate with back button
const RecomputeButton = styled.button`
  position: absolute;
  @media (min-width: ${(props) => props.theme.breakpoints[500]}) {
    right: 60px;
  }
  top: 10px;
  color: black;
  background-color: ${theme.colors.white[700]};
  font-size: 14px;
  font-weight: 500;
  border: none;
  border-radius: 12px;
  padding: 10px 24px;
  cursor: pointer;
  width: 50vw;
  max-width: 300px;
  &:hover {
    background-color: ${theme.colors.white[500]};
  }
`;

type OptionTitle = "Debt Management" | "File Bankruptcy" | "Negotiate Debt" | "Consolidate Debt";
export type TOptionInfo = {
  title: OptionTitle;
  optionName: "Debt Management" | "Ch. 7 Bankruptcy" | "Debt Negotiation" | "Consolidation";
  description: string;
  isEligible: boolean | undefined;
  goodForStatement: string;
  actions: React.ReactNode;
  totalPayment: number;
  monthsInRepayment: number;
  savings: number;
  interest: number;
  fees: number;
  timeToSetUp: string;
  paymentInformation: { fieldName: string; value: number };
  debtTimelineBreakdown: { fieldName: string; duration: number; details?: string };
  successRate: number;
  canAfford: boolean | undefined;
  monthlyPayment: number;
  notEligibleMessage: string | undefined;
  savingsBreakdown: { item: ReactNode; value: string }[];
  canAffordMessage: ReactNode;
  savingsQuestions: string[];
  faqQuestions: string[];
};

export const ResultsComparison = (props: {
  onRecompute: () => void;
  unsecuredDebtEstimate: number;
  availableMonthlyFunds: number;
  dmpEligibilityResults: EligibilityResult;
  bankruptcyEligibilityResults: EligibilityResult;
  settlementEligibilityResults: EligibilityResult;
  consolidationEligibilityResults: EligibilityResult;
  trackStepAction: (actionId: string, extras?: Record<string, any>) => void;
  trackImpression: (subComponentName: string) => void;
  setIsDetailsOpen: (isDetailsOpen: boolean) => void;
}) => {
  const [detailViewOption, setDetailViewOption] = useState<null | OptionTitle>(null);
  const scrollableDivRef = useRef<HTMLDivElement | null>(null);

  const onClickViewDetails = (option: OptionTitle) => {
    props.trackStepAction(`details.opened.${option.replace(" ", "_")}`);
    setDetailViewOption(option);
    props.setIsDetailsOpen(true);
  };
  useEffect(() => {
    return () => props.setIsDetailsOpen(false);
  }, []);

  const statusQuoInterest = getStatusQuoCompoundInterest(props.unsecuredDebtEstimate, props.availableMonthlyFunds);
  const statusQuoPaymentInfo = getStatusQuoRepaymentInfo(props.unsecuredDebtEstimate, props.availableMonthlyFunds);

  const resultCardInfo: TOptionInfo[] = [
    {
      title: "File Bankruptcy",
      optionName: "Ch. 7 Bankruptcy",
      isEligible: props.bankruptcyEligibilityResults.isEligible,
      description: props.bankruptcyEligibilityResults.isEligible
        ? "Eliminate your debt by filing for bankruptcy with Upsolve's free tool."
        : "Eliminate your debt by filing for bankruptcy in the U.S. court system.",
      goodForStatement: "Individuals with overwhelming debt who need a fresh start.",
      savingsQuestions: props.bankruptcyEligibilityResults.isEligible
        ? ["Is filing with Upsolve free?"]
        : ["How much does filing for bankruptcy cost?"],
      faqQuestions: [
        "What is Chapter 7 bankruptcy?",
        "Who qualifies for Chapter 7 bankruptcy?",
        "How does Chapter 7 bankruptcy affect credit?",
      ],
      actions: (
        <>
          {props.bankruptcyEligibilityResults.isEligible ? (
            <Button
              onClick={(e) => {
                e.stopPropagation();
                //  in other CTAs, we use "CTA.myUpsolveNavigation". this change
                //  will affect clickrates on existing charts. I'm ok with this
                //  because we already have multiple products on my.upsolve.org.
                //  but we may need to update those charts.
                props.trackStepAction("CTA.bankruptcy.screener");
                window.open("https://my.upsolve.org/bankruptcy-screener");
              }}
              data-test-label="explore-free-tool-button"
            >
              Get Started
            </Button>
          ) : (
            <Button
              onClick={(e) => {
                e.stopPropagation();
                props.trackStepAction("CTA.bankruptcy.referral");
                window.open("https://my.upsolve.org/referral");
              }}
              data-test-label="talk-to-lawyer-button"
            >
              Talk to a Lawyer
            </Button>
          )}
        </>
      ),
      totalPayment: props.bankruptcyEligibilityResults.totalPayment,
      monthsInRepayment: props.bankruptcyEligibilityResults.monthsInRepayment,
      savings: props.bankruptcyEligibilityResults.savings,
      interest: props.bankruptcyEligibilityResults.interest,
      fees: props.bankruptcyEligibilityResults.fees,
      paymentInformation: {
        fieldName: "One-Time Payment",
        value:
          props.bankruptcyEligibilityResults.isEligible === undefined
            ? 2000 + props.bankruptcyEligibilityResults.fees
            : props.bankruptcyEligibilityResults.fees,
      },
      debtTimelineBreakdown: {
        fieldName: "Debt-Free Date",
        duration: 3,
        // details: `If you have a successful bankruptcy filing and receive a discharge, dischargeable debts will be cleared. The process typically takes 3-4 months, but can vary based on a filer's specific circumstances.`,
      },
      timeToSetUp: "3-4 hours",
      successRate: 97,
      canAfford: props.bankruptcyEligibilityResults.canAfford,
      monthlyPayment: props.bankruptcyEligibilityResults.monthlyPayment,
      notEligibleMessage: props.bankruptcyEligibilityResults.notEligibleMessage,
      savingsBreakdown: [
        { item: "Eliminated in bankruptcy", value: `- $${props.unsecuredDebtEstimate.toLocaleString()}` },
        { item: "Filing fees", value: `+ $${props.bankruptcyEligibilityResults.fees}` },
        {
          item:
            props.bankruptcyEligibilityResults.isEligible === undefined ? "Lawyer fees" : "Cost to file with Upsolve",
          value: props.bankruptcyEligibilityResults.isEligible === undefined ? "+ $2000" : "+ $0",
        },
      ],
      canAffordMessage: (
        <div>
          {props.bankruptcyEligibilityResults.isEligible && (
            <>
              Filing with Upsolve is free. You may have to pay the court's $338 filing fee.
              <br />
              <br />
            </>
          )}
          Filing with a lawyer costs $2,000-$4,000 on average. It is possible to file on your own, paying only the
          court's $338 filing fee, the cost of printing your documents, and the cost of transit to and from the court.
        </div>
      ),
    },
    {
      title: "Debt Management",
      optionName: "Debt Management",
      isEligible: props.dmpEligibilityResults.isEligible,
      description: "Reduce your interest rate by enrolling in Cambridge Credit Counseling's Debt Management Plan.",
      goodForStatement: "Individuals with a steady income who want to reduce their interest rates.",
      canAfford: props.dmpEligibilityResults.canAfford,
      savingsQuestions: ["How much does debt management cost?"],
      faqQuestions: [
        "What is a debt management plan?",
        "How does a debt management plan work?",
        "What types of debts can be included in a debt management plan?",
      ],
      actions: props.dmpEligibilityResults.isEligible ? (
        <Button
          onClick={(e) => {
            e.stopPropagation();
            props.trackStepAction("CTA.DMP");

            window.open("https://calendly.com/camupsolve-appt/upsolve-appointment");
          }}
          data-test-label="get-started-button"
        >
          Get Started
        </Button>
      ) : (
        <Button
          onClick={(e) => {
            e.stopPropagation();
            props.trackStepAction("CTA.DMP");

            window.open("https://upsolve.org/learn/debt-management-plan/");
          }}
          data-test-label="get-started-button"
        >
          Learn More
        </Button>
      ),
      totalPayment: props.dmpEligibilityResults.totalPayment,
      monthlyPayment: props.dmpEligibilityResults.monthlyPayment,
      savings: props.dmpEligibilityResults.savings,
      interest: props.dmpEligibilityResults.interest,
      fees: props.dmpEligibilityResults.fees,
      paymentInformation: {
        fieldName: "Monthly Payment",
        value: props.dmpEligibilityResults.monthlyPayment,
      },
      debtTimelineBreakdown: {
        fieldName: "Debt-Free Date",
        duration: props.dmpEligibilityResults.monthsInRepayment,
      },
      timeToSetUp: "3-4 hours",
      successRate: 80,
      monthsInRepayment: props.dmpEligibilityResults.monthsInRepayment,
      notEligibleMessage: props.dmpEligibilityResults.notEligibleMessage,
      savingsBreakdown: [
        { item: "+ Estimated interest at 8%", value: `+ $${props.dmpEligibilityResults.interest.toLocaleString()}` },
        { item: "+ Estimated fees", value: `+ $${props.dmpEligibilityResults.fees.toLocaleString()}` },
      ],
      canAffordMessage: props.dmpEligibilityResults.canAfford ? (
        <div>
          If you pay your available funds of ${props.availableMonthlyFunds.toLocaleString()} each month, this option
          makes sense.
        </div>
      ) : (
        <div>
          Your available monthly funds of ${props.availableMonthlyFunds.toLocaleString()} won't cover the interest on a
          Debt Management Plan.
        </div>
      ),
    },
    {
      title: "Negotiate Debt",
      optionName: "Debt Negotiation",
      isEligible: props.settlementEligibilityResults.isEligible,
      description: "Reduce your debt balances by negotiating reductions with your creditors.",
      goodForStatement:
        "People who are more than 90 days past due on at least one debt, and have enough savings to pay down half of their debt in a lump sum.",

      canAfford: props.settlementEligibilityResults.canAfford,
      savingsQuestions: ["How much does debt negotiation cost?"],
      faqQuestions: [
        "Who is debt negotiation good for?",
        "Can I negotiate a debt settlement on my own?",
        "How much can I save through debt negotiation?",
      ],
      actions: (
        <Button
          onClick={(e) => {
            e.stopPropagation();
            props.trackStepAction("CTA.Settlement");
            window.open("https://upsolve.org/learn/debt-settlement");
          }}
          data-test-label="learn-more-button"
        >
          Learn More
        </Button>
      ),
      totalPayment: props.settlementEligibilityResults.totalPayment,
      monthlyPayment: props.settlementEligibilityResults.monthlyPayment,
      savings: props.settlementEligibilityResults.savings,
      interest: props.settlementEligibilityResults.interest,
      fees: props.settlementEligibilityResults.fees,
      paymentInformation: {
        fieldName: "One-Time Payment",
        value: props.settlementEligibilityResults.totalPayment,
      },
      debtTimelineBreakdown: {
        fieldName: "Debt-Free Date",
        duration: 2,
      },
      timeToSetUp: props.settlementEligibilityResults.isEligible ? "3-4 hours" : "N/A",
      successRate: 50,
      monthsInRepayment: props.settlementEligibilityResults.monthsInRepayment,
      notEligibleMessage: props.settlementEligibilityResults.notEligibleMessage,
      savingsBreakdown: [
        {
          item: "Eliminated in settlement",

          value: `- $${Math.round(
            props.unsecuredDebtEstimate - props.settlementEligibilityResults.totalPayment
          ).toLocaleString()}`,
        },
      ],
      canAffordMessage: props.settlementEligibilityResults.canAfford ? (
        <div>You have enough savings to start a debt negotiation.</div>
      ) : (
        <div>To negotiate a debt, you'd need monthly income and enough savings to cover half of the debt.</div>
      ),
    },
    {
      title: "Consolidate Debt",
      optionName: "Consolidation",
      isEligible: props.consolidationEligibilityResults.isEligible,
      description: "Reduce your monthly payment and get a lower interest rate by combining multiple debts into one.",
      goodForStatement:
        "People with credit scores above 500 who can afford to make monthly payments around 75% of what they currently pay until their debt is paid in full.",
      canAfford: props.consolidationEligibilityResults.canAfford,
      savingsQuestions: ["How much does debt consolidation cost?"],
      faqQuestions: [
        "Who is debt consolidation good for?",
        "Can I do debt consolidation by myself without help?",
        "What types of debts can be consolidated?",
      ],
      actions: (
        <Button
          onClick={(e) => {
            e.stopPropagation();
            props.trackStepAction("CTA.Consolidation");
            window.open("https://upsolve.org/learn/debt-consolidation");
          }}
          data-test-label="learn-more-button"
        >
          Learn More
        </Button>
      ),
      totalPayment: props.consolidationEligibilityResults.totalPayment,
      monthlyPayment: props.consolidationEligibilityResults.monthlyPayment,
      savings: props.consolidationEligibilityResults.savings,
      interest: props.consolidationEligibilityResults.interest,
      fees: props.consolidationEligibilityResults.fees,
      paymentInformation: {
        fieldName: "Monthly Payment",
        value: props.consolidationEligibilityResults.monthlyPayment,
      },
      debtTimelineBreakdown: {
        fieldName: "Debt-Free Date",
        duration: props.consolidationEligibilityResults.monthsInRepayment,
      },
      timeToSetUp: "2-3 hours",
      successRate: 100,
      monthsInRepayment: props.consolidationEligibilityResults.monthsInRepayment,
      notEligibleMessage: props.consolidationEligibilityResults.notEligibleMessage,
      savingsBreakdown: [
        {
          item: (
            <div>
              + Estimated interest at{" "}
              <Tooltip
                content={
                  <div>
                    Based on the average&nbsp;
                    <a
                      target="_blank"
                      href="https://www.bankrate.com/loans/personal-loans/average-personal-loan-rates/"
                    >
                      personal loan interest rate
                    </a>
                    &nbsp;in 2024
                  </div>
                }
              >
                <span style={{ textDecoration: "underline" }}>12.5%</span>
              </Tooltip>
            </div>
          ),
          value: `+ $${props.consolidationEligibilityResults.interest.toLocaleString()}`,
        },
        { item: "+ Estimated fees", value: `+ $${props.consolidationEligibilityResults.fees.toLocaleString()}` },
      ],
      canAffordMessage: props.consolidationEligibilityResults.canAfford ? (
        <div>
          If you pay your available funds of ${props.availableMonthlyFunds.toLocaleString()} each month, this option
          makes sense.
        </div>
      ) : (
        <div>
          Your available monthly funds of ${props.availableMonthlyFunds.toLocaleString()} won't cover the interest of
          your consolidated debt.
        </div>
      ),
    },
  ];

  if (detailViewOption) {
    const optionInfo = resultCardInfo.find((option) => option.title === detailViewOption);
    // deal with typescript later - this is always true
    if (optionInfo)
      return (
        <StyledResultsScreen>
          <DetailCard
            unsecuredDebtEstimate={props.unsecuredDebtEstimate}
            availableMonthlyFunds={props.availableMonthlyFunds}
            statusQuoInfo={{
              interest: statusQuoInterest,
              monthlyPayment: props.availableMonthlyFunds,
              monthsInRepayment: statusQuoPaymentInfo.monthsInRepayment,
              totalPayment: statusQuoPaymentInfo.totalPayment,
              canAfford: statusQuoPaymentInfo.canAfford,
            }}
            onClickBack={() => {
              props.trackStepAction(`details.closed.${detailViewOption}`);
              setDetailViewOption(null);
              props.setIsDetailsOpen(false);
            }}
            optionInfo={optionInfo}
          />
          <DebtAdvisorChat />
        </StyledResultsScreen>
      );
  }

  /*
  Options are sorted in the following ways:
  1: Products we offer
  2: Eligibility
  3: Affordability
  */

  const sortedResultCardInfo = [
    ...resultCardInfo.filter((option) => option.isEligible && option.canAfford),
    ...resultCardInfo.filter((option) => option.isEligible && !option.canAfford),
    ...resultCardInfo.filter((option) => option.isEligible === undefined),
    ...resultCardInfo.filter((option) => option.isEligible === false),
  ];

  return (
    <StyledResultsScreen>
      <RecomputeButton
        onClick={() => {
          props.trackStepAction("changeAnswers");
          props.onRecompute();
        }}
        data-test-label="change-answers-button"
      >
        Change Answers
      </RecomputeButton>
      <StyledResultsComparisonPanel ref={scrollableDivRef}>
        {sortedResultCardInfo.map((cardInfo) => (
          <ResultCard
            key={cardInfo.title}
            title={cardInfo.title}
            optionName={cardInfo.optionName}
            isEligible={cardInfo.isEligible}
            description={cardInfo.description}
            goodForStatement={cardInfo.goodForStatement}
            actions={cardInfo.actions}
            unsecuredDebt={props.unsecuredDebtEstimate}
            onClickLearnMore={() => onClickViewDetails(cardInfo.title)}
            paymentInformation={cardInfo.paymentInformation}
            debtTimelineBreakdown={cardInfo.debtTimelineBreakdown}
            timeToSetUp={cardInfo.timeToSetUp}
            successRate={cardInfo.successRate}
            savings={cardInfo.savings}
            notEligibleMessage={cardInfo.notEligibleMessage}
            trackImpression={props.trackImpression}
          />
        ))}
      </StyledResultsComparisonPanel>
      <div className="reviews__controller">
        <span
          className="scroll-reviews-back"
          onClick={() => scrollableDivRef.current?.scrollBy({ left: -360, behavior: "smooth" })}
        >
          <IconCaret />
        </span>
        <span
          className="scroll-reviews-forward"
          onClick={() => scrollableDivRef.current?.scrollBy({ left: 360, behavior: "smooth" })}
        >
          <IconCaret />
        </span>
      </div>
      <RecomputeButton
        onClick={() => {
          props.trackStepAction("changeAnswers");
          props.onRecompute();
        }}
      >
        ← Change Answers
      </RecomputeButton>
      <DebtAdvisorChat />
    </StyledResultsScreen>
  );
};

type TResultsCardProps = {
  title: string;
  optionName: string;
  isEligible: boolean | undefined;
  description: string;
  goodForStatement: string;
  actions: React.ReactNode;
  unsecuredDebt: number;
  onClickLearnMore: () => void;
  savings: number;
  paymentInformation: { fieldName: string; value: number };
  debtTimelineBreakdown: { fieldName: string; duration: number };
  timeToSetUp: string;
  successRate: number;
  notEligibleMessage: string | undefined;
  trackImpression: (subComponentName: string) => void;
};
const ResultCard = (props: TResultsCardProps) => {
  const hasBeenViewedRef = useRef(false);
  const elementRef = useRef(null);

  //track if card is viewed
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting && !hasBeenViewedRef.current) {
          hasBeenViewedRef.current = true;
          props.trackImpression(camelCase(props.title));
        }
      },
      { threshold: 1 } // 100% of the component must be visible
    );

    if (elementRef.current) {
      observer.observe(elementRef.current);
    }

    return () => {
      if (elementRef.current) {
        observer.unobserve(elementRef.current);
      }
    };
  }, []);

  return (
    <StyledResultsCardContainer
      isEligible={props.isEligible}
      ref={elementRef}
      onClick={() => props.isEligible && props.onClickLearnMore()}
    >
      <StyledThumbContainer
        data-testid={`${props.optionName.toLowerCase().replace(" ", "-")}-indicator`}
        data-indicator={props.isEligible ? "thumbs-up" : props.isEligible !== false ? "question-mark" : "thumbs-down"}
      >
        {props.isEligible ? <ThumbsUp /> : props.isEligible !== false ? <QuestionMark /> : <ThumbsDown />}
      </StyledThumbContainer>
      <StyledResultCard isEligible={props.isEligible}>
        <div className="title_section">
          <h3>{props.title}</h3>
          <p>{props.description}</p>
        </div>
        {props.isEligible === false ? (
          <div className="ineligible_section">
            <div>{props.notEligibleMessage}</div>
          </div>
        ) : (
          <>
            <div className="savings_section">
              <div className="line_item">
                {props.paymentInformation.fieldName}:{" "}
                <b>${Math.round(props.paymentInformation.value).toLocaleString()}</b>
              </div>
              {props.savings > 0 && (
                <div className="line_item">
                  Savings: <b>${Math.round(props.savings).toLocaleString()}</b>
                </div>
              )}
              <div className="line_item">
                {props.debtTimelineBreakdown.fieldName}:{" "}
                <b>{format(addMonths(new Date(), props.debtTimelineBreakdown.duration), "MMM y")}</b>
              </div>
              {/* <div className="line_item">
              Success Rate: <b>{props.successRate}%</b>
            </div> */}
            </div>
            <div className="actions_section">
              <div className="button-container">
                <Button
                  onClick={props.onClickLearnMore}
                  // TODO data-test-label=""
                >
                  View Details
                </Button>
              </div>
            </div>
          </>
        )}
      </StyledResultCard>
    </StyledResultsCardContainer>
  );
};

const StyledResultsComparisonPanel = styled.div`
  display: flex;
  @media (min-width: 1450px) {
    justify-content: center;
  }
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  overflow-x: scroll;
  overflow-y: hidden;
  gap: 20px;
`;

// TODO: scroll lock
// these min-height queries are kind of abusing our implied width breakpoints,
// but this is checking for SE vs non iPhone SE
const StyledResultsScreen = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: auto;
  overscroll-behavior: contain;

  .num_results_message {
    padding-left: 16px;
    padding-right: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 36px; //space for the close button, can remove once we decide if we actually need it
    @media (max-height: ${(props) => props.theme.breakpoints[500]}) {
      text-align: left;
      font-size: 14px;
      line-height: 18px;
    }
    font-size: 20px;
    .show_mobile_only {
      display: none;
      @media (max-width: ${(props) => props.theme.breakpoints[500]}) {
        display: inline;
        @media (min-height: ${(props) => props.theme.breakpoints[500]}) {
          display: block;
        }
      }
    }
  }
  .reviews__controller {
    @media (min-width: ${(props) => props.theme.breakpoints[800]}) {
      display: none;
    }
    justify-content: center;
    color: ${(props) => props.theme.colors.brand[500]};
    font-size: 40px;
    font-weight: 500;
    line-height: 125%;
    display: flex;
    align-items: center;
    gap: 36px;

    .scroll-reviews-back,
    .scroll-reviews-forward {
      height: 50px;
      width: 50px;
      border-radius: 25px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 3px solid ${(props) => props.theme.colors.brand[700]};
      background: white;
      cursor: pointer;

      svg {
        height: 16px;
        width: 16px;
        path {
          fill: ${(props) => props.theme.colors.brand[500]};
        }
      }
      &:hover {
        border: 3px solid ${(props) => props.theme.colors.brand[500]};
        background: ${(props) => props.theme.colors.brand[500]};
        svg {
          path {
            fill: white;
          }
        }
      }
    }
    .scroll-reviews-back {
      svg {
        transform: rotate(90deg);
      }
    }
    .scroll-reviews-forward {
      svg {
        transform: rotate(-90deg);
      }
    }
  }
`;
const StyledResultCard = styled.div<{ isEligible: boolean | undefined }>`
  display: flex;
  flex-direction: column;
  width: 338px;
  max-height: 500px;
  background-color: white;
  ${(props) => props.isEligible && `box-shadow: ${props.theme.effects.shadow[350]};`}
  padding: 30px 20px 20px 20px;
  border-radius: 16px;
  text-align: center;
  flex: 1;
  margin-top: -30px;
  margin-bottom: 16px;

  @media (min-height: ${(props) => props.theme.breakpoints[500]}) {
    max-height: 700px;
  }

  .title_section {
    padding-top: 20px;
    h3 {
      color: ${(props) => (props.isEligible ? theme.colors.black[300] : theme.colors.gray[700])};
      font-weight: 500;
      font-size: 20px;
      margin-bottom: 8px;
    }
    p {
      height: 76px;
      text-align: center;
      color: ${(props) => (props.isEligible ? theme.colors.gray[500] : theme.colors.gray[700])};
      opacity: 80%;
      margin: 0 0 10px 0;
      width: 100%;
      display: block;
    }
  }

  .savings_section {
    b {
      font-weight: 600;
    }
    font-size: 14px;
    color: ${theme.colors.gray[500]};
    text-align: start;
    width: 100%;
    margin-bottom: 0.4em;
    button {
      margin-top: 0.8em;
    }
    .line_item {
      display: flex;
      justify-content: space-between;
    }
    .line_item + .line_item {
      border-top: 1px dashed ${(props) => props.theme.colors.white[300]};
      margin-top: 5px;
      padding-top: 5px;
    }
  }
  .ineligible_section {
    font-size: 14px;
    text-align: start;
    height: 100%;
    width: 100%;
    margin-top: 1em;
    background-color: #eceffd;
    color: ${(props) => props.theme.colors.gray[700]};
    border-radius: 8px;
    padding: 12px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    .title {
      text-align: center;
    }
  }
  .actions_section {
    display: flex;
    flex: 1;
    align-items: flex-end;

    .button-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 8px;
      margin-top: 16px;
    }
  }
`;
const StyledThumbContainer = styled.div`
  position: relative;
  top: 10px;
`;

const StyledResultsCardContainer = styled.div<{ isEligible: boolean | undefined }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: ${(props) => (props.isEligible ? "pointer" : "auto")};
`;
