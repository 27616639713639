import { theme } from "@upsolve/ui";
import React, { useEffect } from "react";
import styled from "styled-components";
import { postDebtAdvisorSession } from "../analytics/track";
import {
  getDmpEligibilityResults,
  getBankruptcyEligibilityResults,
  getSettlementEligibilityResults,
  getConsolidationEligibilityResults,
  getStatusQuoRepaymentInfo,
  getStatusQuoCompoundInterest,
} from "./eligibilityFunctions";
import { TScreenProps } from "./Screens";
import { Button, DescriptionCentered, QuestionHeading } from "./Components";
import { getCompoundedInterest } from "./getCompoundedInterest";
import { formatMonths } from "./formatMonths";

const StatusQuoScreenWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StatusQuoScreenFakeModal = styled.div`
  padding: 2em;
  box-shadow: 0px 24px 24px rgba(0, 0, 0, 0.05), 0px 16px 24px rgba(0, 0, 0, 0.05);
  background-color: white;
  border-radius: 8px;
  max-width: 500px;
  @media (max-width: ${(p) => p.theme.breakpoints[500]}) {
    max-height: initial;
    min-height: initial;
    max-width: 100%;
    padding: 1em;
    height: 100%;
    border-radius: 0px;
  }

  padding-top: 2em;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  // Modal slide-in animation
`;

const StyledStatusQuoContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  // content
  input:focus,
  select:focus {
    box-shadow: 0 0 5px ${(props) => props.theme.colors.brand["700"]};
  }

  form,
  button,
  input,
  select {
    width: 100%;
  }
  width: 100%;
`;

const StyledStatusQuoBlurb = styled.div`
  display: flex;
  flex-direction: row;
  text-align: start;
  align-items: center;
  padding: 14px;
  margin-bottom: 8px;

  background: #f7f7fa;
  border-radius: 12px;

  .fact {
    white-space: pre-wrap;
    min-width: 7em;
    margin-right: 8px;
    display: flex;
    flex-direction: column;
    align-items: start;
    .value {
      color: ${theme.colors.black[100]};
      font-size: 18px;
      font-weight: 500;
    }
    .label {
      opacity: 70%;
      color: ${theme.colors.black[100]};
      font-size: 12px;
      font-weight: 300;
    }
  }

  .explanation {
    color: ${theme.colors.black[100]};
    font-size: 12px;
    opacity: 70%;
  }
`;

type TStatusQuoBlurbProps = {
  value: string;
  label: string;
  explanation: string;
};

const StatusQuoBlurb = (props: TStatusQuoBlurbProps) => {
  return (
    <StyledStatusQuoBlurb>
      <div className="fact">
        <span className="value">{props.value}</span>
        <span className="label">{props.label}</span>
      </div>
      <div className="explanation">
        <span>{props.explanation}</span>
      </div>
    </StyledStatusQuoBlurb>
  );
};

export const StatusQuoScreen = ({ controls, state, trackStepAction }: TScreenProps) => {
  if (state.hasIncome === undefined) {
    return <div>Error: missing hasIncome</div>;
  }
  if (state.unsecuredDebtEstimate === undefined) {
    return <div>Error: missing unsecuredDebtEstimate</div>;
  }

  if (state.availableMonthlyFunds === undefined) {
    return <div>Error: missing availableMonthlyFunds</div>;
  }

  if (state.hasGoodCreditScore === undefined) {
    return <div>Error: missing hasGoodCreditScore </div>;
  }

  if (state.householdSize === undefined) {
    return <div>Error: missing householdSize</div>;
  }

  const dmpEligibilityResults = getDmpEligibilityResults(
    state.hasIncome,
    state.unsecuredDebtEstimate,
    state.availableMonthlyFunds
  );
  const bankruptcyEligibilityResults = getBankruptcyEligibilityResults(
    state.passesMeansTest || !state.hasIncome,
    state.unsecuredDebtEstimate,
    state.zipcode,
    state.availableMonthlyFunds
  );

  const settlementEligibilityResults = getSettlementEligibilityResults(
    state.passesMeansTest || false,
    state.availableMonthlyFunds,
    state.hasAccessToCapital || false,
    state.unsecuredDebtEstimate
  );
  const consolidationEligibilityResults = getConsolidationEligibilityResults(
    state.unsecuredDebtEstimate,
    state.availableMonthlyFunds,
    state.hasGoodCreditScore
  );

  const numberOfOptions = Object.values([
    dmpEligibilityResults,
    bankruptcyEligibilityResults,
    settlementEligibilityResults,
    consolidationEligibilityResults,
  ]).filter((result) => result.isEligible).length;

  const availableOptionsMessage = (() => {
    if (numberOfOptions > 1)
      return (
        <>
          We found <b>{`${numberOfOptions}`} options</b> that can save you time and money.
        </>
      );
    if (numberOfOptions === 1)
      return (
        <>
          We found <b>1 option</b> that can save you time and money.
        </>
      );
    // TODO: copy
    return (
      <>
        Unfortunately, we are unable to suggest any debt reduction offerings to you at this time. You can still view
        your results to understand our reasoning.
      </>
    );
  })();
  const statusQuoPaymentInfo = getStatusQuoRepaymentInfo(state.unsecuredDebtEstimate, state.availableMonthlyFunds);
  const statusQuoInterest = getStatusQuoCompoundInterest(state.unsecuredDebtEstimate, state.availableMonthlyFunds);

  const statusQuoInterestInFiveYears = getCompoundedInterest(
    state.unsecuredDebtEstimate,
    0.23,
    5,
    state.availableMonthlyFunds
  );

  const trackSession = async () => {
    await postDebtAdvisorSession({
      inputs: state,
      results: {
        statusQuoResults: {
          interest: statusQuoInterest,
          ...statusQuoPaymentInfo,
        },
        dmpEligibilityResults,
        bankruptcyEligibilityResults,
        settlementEligibilityResults,
        consolidationEligibilityResults,
      },
    });
  };

  useEffect(() => {
    //track session results as tabular data in postgres for more complex analysis
    trackSession();

    // goal of this is to track how many users qualified for which thing. I think the simplest way is to only track which qualified.
    if (dmpEligibilityResults.isEligible) trackStepAction("isEligible.dmp");
    if (bankruptcyEligibilityResults.isEligible) trackStepAction("isEligible.bankruptcy.upsolve");
    if (settlementEligibilityResults.isEligible) trackStepAction("isEligible.settlement");
    if (consolidationEligibilityResults.isEligible) trackStepAction("isEligible.consolidation");
  }, []);

  if (!statusQuoPaymentInfo.canAfford) {
    const fiveYearsOfPayments = 5 * 12 * state.availableMonthlyFunds;
    const debtInFiveYears = state.unsecuredDebtEstimate + statusQuoInterestInFiveYears - fiveYearsOfPayments;
    return (
      <StatusQuoScreenWrapper>
        <StatusQuoScreenFakeModal>
          <StyledStatusQuoContent>
            <QuestionHeading style={{ marginBottom: "10px", textAlign: "center" }}>
              You won't be able to pay off your debt
            </QuestionHeading>
            <>
              <div style={{ marginTop: "16px", marginBottom: "16px" }}>
                <StatusQuoBlurb
                  value={`$${Math.round(state.unsecuredDebtEstimate).toLocaleString()}`}
                  label={"Current Debt"}
                  explanation={"This is the debt you told us you currently owe."}
                />
                <StatusQuoBlurb
                  value={`$${Math.round(debtInFiveYears).toLocaleString()}`}
                  label={"Debt In 5 Years"}
                  explanation={
                    "Assuming 23% annual interest, your overall debt balance will increase over the next 5 years."
                  }
                />
                <StatusQuoBlurb
                  value={"Never"}
                  label={"Estimated Payoff"}
                  explanation={`You will not be able to pay off your debt if you continue to pay $${state.availableMonthlyFunds.toLocaleString()}/month towards your debt.`}
                />
              </div>
            </>
            <DescriptionCentered>{availableOptionsMessage}</DescriptionCentered>
            <Button
              onClick={() => {
                controls.onNext();
              }}
              data-test-label="see-options-button"
            >
              View My Results
            </Button>
          </StyledStatusQuoContent>
        </StatusQuoScreenFakeModal>
      </StatusQuoScreenWrapper>
    );
  }

  const totalPaid = state.unsecuredDebtEstimate + statusQuoInterest;
  console.log(formatMonths(statusQuoPaymentInfo.monthsInRepayment));

  return (
    <StatusQuoScreenWrapper>
      <StatusQuoScreenFakeModal>
        <StyledStatusQuoContent>
          <QuestionHeading style={{ marginBottom: "10px", textAlign: "center" }}>
            If you continue to pay off your debt...
          </QuestionHeading>
          <>
            <div style={{ marginTop: "16px", marginBottom: "16px" }}>
              <StatusQuoBlurb
                value={`$${Math.round(state.unsecuredDebtEstimate).toLocaleString()}`}
                label={"What You Owe"}
                explanation={"This is the debt you told us you currently owe."}
              />
              <StatusQuoBlurb
                value={`$${Math.round(totalPaid).toLocaleString()}`}
                label={"What You'll Pay"}
                explanation={"This is the total you’ll pay over time assuming 23% annual interest."}
              />
              <StatusQuoBlurb
                value={`${formatMonths(statusQuoPaymentInfo.monthsInRepayment)}`}
                label={"Estimated Payoff"}
                explanation={
                  "This is how long it will take to pay off your debt if you continue to make payments today."
                }
              />
            </div>
          </>
          <DescriptionCentered>{availableOptionsMessage}</DescriptionCentered>
          <Button
            onClick={() => {
              controls.onNext();
            }}
            data-test-label="see-options-button"
          >
            View My Results
          </Button>
        </StyledStatusQuoContent>
      </StatusQuoScreenFakeModal>
    </StatusQuoScreenWrapper>
  );
};
