import React from "react";
import styled from "styled-components";
import { theme } from "@upsolve/ui";
// @ts-ignore
import SparklesIcon from "./images/sparkles-icon.svg";

const IconText = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: white;
  flex: none;
  order: 1;
  flex-grow: 0;
  user-select: none;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const ChatIconWrapper = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  width: 166.667px;
  height: 40px;
  padding: 6.667px 10.667px;
  justify-content: center;
  align-items: center;
  gap: 5.333px;
  border-radius: 16px;
  cursor: pointer;
  background: ${theme.colors.brand[500]};
  &:hover {
    background: ${theme.colors.brand[300]};
  }
  box-sizing: border-box;
  box-shadow: 0px 24px 24px rgba(0, 0, 0, 0.05), 0px 8px 16px rgba(0, 0, 0, 0.05), 0px 16px 24px rgba(0, 0, 0, 0.05);

  @media screen and (max-width: 768px) {
    width: 40px;
    height: 40px;
    padding: 8px;
    border-radius: 20px;
    margin-right: 0.9em;
  }
`;

const ChatIcon: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <ChatIconWrapper onClick={onClick}>
      <SparklesIcon />
      <IconText>Debt Assistant</IconText>
    </ChatIconWrapper>
  );
};

export default ChatIcon;
