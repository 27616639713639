import React, { Fragment, useEffect, useState } from "react";
import Helmet from "react-helmet";
import { QueryClientProvider } from "react-query";
import { ThemeProvider } from "styled-components";
import { theme, Input, Button, P } from "@upsolve/ui";
import create from "zustand";

import { queryClient } from "../queries/queryClient";
import config from "../../../site-config";
import GlobalStyle from "../GlobalStyle";
import Hotjar from "@hotjar/browser";

//initializing here because for whatever reason it isn't working in the html.jsx file
//IMPORTANT: they seem to reject requests from http web sessions, so note that
//things do not work locally which is good because we don't want local data, but
//annoying for testing
const SITE_ID = 5072883;
const HOTJAR_VERSION = 6;
Hotjar.init(SITE_ID, HOTJAR_VERSION);

const useAccessStore = create((set) => ({
  accessEnabled: false,
  setAccessEnabled: (accessEnabled) => set({ accessEnabled }),
}));

const DevAccessCodeGuard = ({ setAccessEnabled }) => {
  const [input, setInput] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (input.toLowerCase().trim() === "bktech150") {
      setAccessEnabled(true);
    }
    setInput("");
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <form
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "300px",
            height: "300px",
          }}
          onSubmit={handleSubmit}
        >
          <P>Please enter access code</P>
          <Input
            id="access-code"
            type="text"
            value={input}
            data-test-label="access-code"
            onChange={(e) => setInput(e.target.value)}
            style={{ width: "100%", boxSizing: "border-box" }}
          />
          <br />
          <Button type="submit" style={{ width: "100%" }}>
            Submit
          </Button>
        </form>
      </div>
    </div>
  );
};

const TopLevelLayout = (props) => {
  useEffect(() => console.log(upsolveConsoleLog), []);
  const store = useAccessStore();
  const isDevEnv = NODE_ENV === 'development';
  const [shouldShowDevAccessGuard, setShouldShowDevAccessGuard] = useState(true);

  useEffect(() => {
    // Check if the secret key is present in the URL search params
    // This is used so tests in deploying frontdoor into the dev env pass. This isn't needed for circleCI PR tests.
    const urlParams = new URLSearchParams(window.location.search);
    const secretKey = urlParams.get('secret_key');
    const isValidSecretKey = secretKey === 'bktech150';

    // Show the DevAccessCodeGuard only with invalid secret key
    setShouldShowDevAccessGuard(!isValidSecretKey);
  }, []);

  if (shouldShowDevAccessGuard && isDevEnv && !store.accessEnabled) {
    return (
      <Fragment>
        <GlobalStyle />
        <Helmet>
          <title>{config.siteTitle}</title>
          <meta name="description" content={config.siteDescription} />
        </Helmet>
        <DevAccessCodeGuard setAccessEnabled={store.setAccessEnabled} />
      </Fragment>
    );
  }

  return (
    <Fragment>
      <GlobalStyle />
      <Helmet>
        <title>{config.siteTitle}</title>
        <meta name="description" content={config.siteDescription} />
      </Helmet>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          {props.children}
        </ThemeProvider>
      </QueryClientProvider>
    </Fragment>
  );
};

const upsolveConsoleLog = `
██████████████████████████████████████████████████████████

██╗   ██╗██████╗ ███████╗ ██████╗ ██╗    ██╗   ██╗███████╗
██║   ██║██╔══██╗██╔════╝██╔═══██╗██║    ██║   ██║██╔════╝
██║   ██║██████╔╝███████╗██║   ██║██║    ██║   ██║█████╗
██║   ██║██╔═══╝ ╚════██║██║   ██║██║    ╚██╗ ██╔╝██╔══╝
╚██████╔╝██║     ███████║╚██████╔╝███████╗╚████╔╝ ███████╗
 ╚═════╝ ╚═╝     ╚══════╝ ╚═════╝ ╚══════╝ ╚═══╝  ╚══════╝

██████████████████████████████████████████████████████████

👋 Yo!
👩‍💻 Would you like to:

⚬ Improve access to an under-utilized social safety net?
⚬ Create a countering force to predatory lenders?
⚬ Change the perception of what a nonprofit can do?
⚬ Get in a good kind of a trouble?

📬 Email: dev@upsolve.org

██████████████████████████████████████████████████████████
`;

export default TopLevelLayout;
