import axios from "axios";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { TScreenProps } from "./Screens";
import { Button } from "./Components";

const StyledWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  pointer-events: none;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2147483648;
  .hover-bar {
    position: absolute;
    bottom: 10px;
    z-index: 2147483648;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;
  }

  button {
    pointer-events: auto;
    width: 300px;
  }
`;

type TNexusPayload = {
  nexusEvent: {
    name: string;
    metadata: any;
    consumer: {
      uuid: string;
    };
    workflow: string;
    happened_at: string;
  };
};

const updateNexusWidgetStyling = () => {
  // Select the shadow host
  const shadowHost = document.querySelector("nexus-widget");
  if (shadowHost === null) {
    return;
  }

  // Access the shadow DOM
  const shadowRoot = shadowHost.shadowRoot;
  if (shadowRoot === null) {
    return;
  }
  shadowRoot.innerHTML =
    `
  <style>
		#nexus-modal {
			background: none !important;
		}
  </style>
` + shadowRoot.innerHTML;
};

const PayItOffScreen = (props: TScreenProps) => {
  // TODO: this is if we keep the debt results screen. Can remove once we've finalized the replacement screen
  // const [isDone, setIsDone] = useState(false);
  const getDebts = async (consumerUuid: string) => {
    const resp = await axios.get(`${UPSOLVE_API_URL}/v1/debtAdvisor/list-consumer-debts-from-payitoff`, {
      params: { consumerUuid },
    });
    return resp.data.data;
  };

  const closeNexusWidget = () => {
    const nexusWidget = document.querySelector("#nexus-widget-anchor");
    if (nexusWidget) {
      nexusWidget.remove();
      props.controls.onNext();
    } else {
      console.error("Failed to close nexus widget");
    }
  };

  const initializeNexus = async () => {
    //@ts-ignore
    await Nexus({
      nexus_key: PAYITOFF_NEXUS_KEY,
      borrower: `upsolvetest+${Date.now()}`,
    });

    //@ts-ignore
    Nexus.linkDebts({
      onEvent: async function (response: TNexusPayload) {
        //TODO: on error or on not discovering a user

        console.log(response);
        if (response.nexusEvent.name === "quit") {
          props.controls.onClose();
        }

        if (response.nexusEvent.name === "debts_linked") {
          closeNexusWidget();
          const consumerUuid = response.nexusEvent.consumer.uuid;
          const debts = await getDebts(consumerUuid);
          //filter out student loan debts and other non-dischargeable debts
          const unsecureDebts = debts.filter((debt: any) => !["STUDENT_LOAN", "MORTGAGE"].includes(debt.type));
          const unsecureDebtTotal = unsecureDebts
            .map((debt: any) => Number(debt.balance))
            .reduce((acc: number, balance: number) => acc + balance, 0);
          props.updateState({ unsecuredDebtEstimate: unsecureDebtTotal });

          const otherDebts = debts.filter((debt: any) => ["STUDENT_LOAN", "MORTGAGE"].includes(debt.type));
          const otherDebtTotal = otherDebts
            .map((debt: any) => Number(debt.balance))
            .reduce((acc: number, balance: number) => acc + balance, 0);
          props.updateState({ otherDebtEstimate: otherDebtTotal });
          // setIsDone(true);
        }
      },
    });
    updateNexusWidgetStyling();
  };

  useEffect(() => {
    initializeNexus();
  }, []);

  return (
    <StyledWrapper>
      {/* {isDone && (
        <div className="hover-bar">
          <Button onClick={closeNexusWidget}>Continue</Button>
        </div>
      )} */}
    </StyledWrapper>
  );
};

export default PayItOffScreen;
