import React, { useCallback, useRef } from "react";
import axios from "axios";
import create from "zustand";
import { addYears, format } from "date-fns";
import { v4 as uuidV4 } from "uuid";
import { UPSOLVE_IDENTIFIER_COOKIE } from "@upsolve/shared";
import styled from "styled-components";
import { theme } from "@upsolve/ui";

//TODO: move all this cookie stuff/find out if we can reuse code
const COOKIE_DOMAIN = process.env.NODE_ENV !== "local" ? ".upsolve.org" : "localhost";

const setCookie = (name: string, value: string) => {
  const twoYearsFromNow = addYears(new Date(), 2);
  const cookieParameters = {
    [name]: value,
    domain: COOKIE_DOMAIN,
    expires: format(twoYearsFromNow, "EEE, dd MMM yyyy HH:mm:ss OOO"),
    path: "/",
  };
  document.cookie = Object.entries(cookieParameters)
    .map(([key, value]) => `${key}=${value}`)
    .join("; ");
};

const getCookie = (name: string) => {
  const cookies = document.cookie.split(";");
  for (let cookie of cookies) {
    const [cookieName, cookieValue] = cookie.trim().split("=");
    if (cookieName === name) {
      return decodeURIComponent(cookieValue);
    }
  }
  return null;
};

const refreshCookie = (name: string) => {
  const oldCookieValue = getCookie(name);
  if (oldCookieValue) {
    setCookie(name, oldCookieValue);
  }
};

const findOrCreateAnonymousId = () => {
  const currentAnonymousId = getCookie(UPSOLVE_IDENTIFIER_COOKIE);
  if (currentAnonymousId) {
    refreshCookie(UPSOLVE_IDENTIFIER_COOKIE);
    return currentAnonymousId;
  }

  const anonymousId = uuidV4();
  setCookie(UPSOLVE_IDENTIFIER_COOKIE, anonymousId);
  return anonymousId;
};

export interface IChatMessage {
  id: number;
  sender: "user" | "ai";
  text: string | React.ReactNode;
  createdAt: string;
  articleLink?: string;
}

type ChatStore = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;

  chatId: number | null;
  setChatId: (chatId: number) => void;

  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;

  history: IChatMessage[];
  appendToHistory: (message: IChatMessage) => void;
  appendMultipleToHistory: (messages: IChatMessage[]) => void;
};

const StyledChatIntroMessage = styled.div`
  b {
    margin-bottom: 12px;
  }
  hr {
    border: none;
    border-top: 1px solid ${theme.colors.brand["600"]};
    margin: 16px 0;
  }
  ul {
    list-style-type: none;
    padding-left: 0;
  }
  ul li {
    display: flex; /* Align checkmark and text in one line */
    align-items: flex-start;
  }
  ul li::before {
    content: "✔";
    font-weight: bold;
    margin-right: 8px;
    flex-shrink: 0;
  }
  .check {
    padding-right: 8px;
  }
  p {
    margin-bottom: 8px;
    margin-top: 8px;
  }
`;

const OpeningMessage: React.FC = () => (
  <StyledChatIntroMessage>
    <b>Meet AI Debt Assist </b>
    <ul>
      <li>Available 24/7 to help you</li>
      <li>Answers to help you reduce your debt</li>
      <li>Answers come only from Upsolve’s 2,000+ expert-written articles on debt topics</li>
      <li>Includes access to an Upsolve staff member if you're struggling</li>
    </ul>
    <hr />
    AI Debt Assistant is a digital helper that’s still learning, so please review all responses. Your questions and the
    AI responses are recorded. Here's what you can expect from us: {" "}
    <a href="https://upsolve.org/learn/upsolve-commitments" target="_blank">
      https://upsolve.org/learn/upsolve-commitments
    </a>
  </StyledChatIntroMessage>
);

const getInitialChatHistory = (): IChatMessage[] => {
  return [
    {
      id: Date.now(),
      sender: "ai",
      text: <OpeningMessage />,
      createdAt: new Date().toISOString(),
    },
  ];
};

const useChatStore = create<ChatStore>((set) => ({
  isOpen: false,
  setIsOpen: (isOpen) => {
    return set({ isOpen: isOpen });
  },

  chatId: null,
  setChatId: (chatId: number) => set(() => ({ chatId })),

  isLoading: false,
  setIsLoading: (isLoading: boolean) => set(() => ({ isLoading })),

  history: getInitialChatHistory(),
  appendToHistory: (message: IChatMessage) => set((state) => ({ history: [...state.history, message] })),
  appendMultipleToHistory: (messages: IChatMessage[]) => 
    set((state) => ({ history: [...state.history, ...messages] })),
}));

async function sendChatMessage(chatId: number | null, message: string, deviceId: string) {
  const response = await axios.post(`${UPSOLVE_API_URL}/v1/debtAdvisor/chat/message`, {
    chatId,
    query: message,
    deviceId,
  });
  return response.data;
}

export const useChat = () => {
  const store = useChatStore();
  const lastTypingTime = useRef<number>(0);
  const followUpTimeoutRef = useRef<NodeJS.Timeout>();

  const deviceId = findOrCreateAnonymousId();

  const writeFollowUpMessage = useCallback(() => {
    // Only send follow-up if user hasn't typed for at least 5 seconds
    const timeSinceLastType = Date.now() - lastTypingTime.current;
    if (timeSinceLastType > 5000) {
      const followUpMessage: IChatMessage = {
        id: Date.now(),
        sender: "ai",
        text: "Do you have any other questions?",
        createdAt: new Date().toISOString(),
      };
      store.appendToHistory(followUpMessage);
    }
  }, [lastTypingTime, store]);

  const askQuestion = async (messageText: string) => {
    // Clear any existing timeout
    if (followUpTimeoutRef.current) {
      clearTimeout(followUpTimeoutRef.current);
    }

    const questionMessage: IChatMessage = {
      id: Date.now(),
      text: messageText,
      sender: "user",
      createdAt: new Date().toISOString(),
    };

    store.appendToHistory(questionMessage);

    try {
      store.setIsLoading(true);
      const { chatId: newChatId, messages } = await sendChatMessage(
        store.chatId,
        //TODO: handle this better. We'll never have a user message as html, only as string
        questionMessage.text as string,
        deviceId
      );
      store.setIsLoading(false);

      if (!store.chatId) {
        store.setChatId(newChatId);
      }

      for (const [index, message] of messages.entries()) {
        // Skip user messages entirely
        if (message.sender === "user") continue;
      
        const chatMessage: IChatMessage = {
          id: Date.now() + index + 1,
          sender: message.sender,
          text: message.text,
          createdAt: new Date().toISOString(),
          articleLink: message.articleSuggestions
            ? JSON.stringify(message.articleSuggestions)
            : undefined,
        };
      
        store.appendToHistory(chatMessage);
      
        // Wait a bit before showing the next message (e.g., 1.5 seconds) unless it's the last message
        if (index < messages.length - 1) {
          await new Promise((resolve) => setTimeout(resolve, 1500));
        }
      }

      followUpTimeoutRef.current = setTimeout(writeFollowUpMessage, 5000);
    } catch (error) {
      const errorMessage: IChatMessage = {
        id: Date.now() + 1,
        sender: "ai",
        text: "Sorry, an error occurred. Please try again.",
        createdAt: new Date().toISOString(),
      };

      //TODO: probably handle errors with its own custom error box
      store.appendToHistory(errorMessage);
    }
  };

  return {
    lastTypingTime,
    isOpen: store.isOpen,
    setIsOpen: store.setIsOpen,
    isLoading: store.isLoading,
    history: store.history,
    askQuestion,
  };
};
