import { H3, Small, theme } from "@upsolve/ui";
import React from "react";
import styled from "styled-components";
import ThumbsUp from "./images/thumbs-up.svg";
import ThumbsDown from "./images/thumbs-down.svg";
import QuestionMark from "./QuestionMark.svg";
import { TOptionInfo } from "./ResultsComparison";
import { formatMonths } from "./formatMonths";
import { DetailHeading, DetailDescriptionCentered, AIPromptButton } from "./Components";
import { useChat } from "./useChat";
import { addMonths, format } from "date-fns";
import { getCompoundedInterest } from "./getCompoundedInterest";

type TStatusQuoInfo = {
  interest: number;
  totalPayment: number;
  monthlyPayment: number;
  monthsInRepayment: number;
  canAfford: boolean;
};

const EmptyRow = () => (
  <div className="row empty">
    <div className="description"></div>
  </div>
);

export const DetailCard = (props: {
  optionInfo: TOptionInfo;
  unsecuredDebtEstimate: number;
  availableMonthlyFunds: number;
  statusQuoInfo: TStatusQuoInfo;
  onClickBack: () => void;
}) => {
  const chat = useChat();

  const clickPromptButton = (messageText: string) => {
    chat.askQuestion(messageText);
    chat.setIsOpen(true);
  };

  const savings = (() => {
    if (!props.optionInfo.isEligible) {
      return "N/A";
    }

    if (props.optionInfo.savings > 0) {
      return `$${Math.round(props.optionInfo.savings).toLocaleString()}`;
    }

    //if we can't calculate user savings for bankruptcy, that means they can't
    //pay off their current debt so we just say they'll discharge all their
    //existing debt
    //TODO: move this check out of here
    if (props.optionInfo.title === "File Bankruptcy") {
      return `$${Math.round(props.unsecuredDebtEstimate).toLocaleString()}+`;
    }

    //if we can't calculate user savings for settlement, that means they can't
    //pay off their current debt so we just say they'll save whatever's chopped off of their original debt amount
    //TODO: move this check out of here
    if (props.optionInfo.title === "Negotiate Debt") {
      return `$${Math.round(props.unsecuredDebtEstimate - props.optionInfo.totalPayment).toLocaleString()}+`;
    }
    return "N/A";
  })();

  const statusQuoSavingsBreakdownCalculations = (() => {
    if (props.statusQuoInfo.canAfford) {
      return {
        principalLabel: "Debt",
        principalValue: `$${props.unsecuredDebtEstimate.toLocaleString()}`,
        interestLabel: "Estimated interest*",
        interestValue: `+ $${Math.round(props.statusQuoInfo.interest).toLocaleString()}`,
        totalLabel: "Total payment",
        totalValue: `= $${Math.round(props.statusQuoInfo.totalPayment).toLocaleString()}`,
        durationLabel: "Time until debt free",
        durationValue: formatMonths(props.statusQuoInfo.monthsInRepayment),
      };
    }
    const statusQuoInterestInFiveYears = getCompoundedInterest(
      props.unsecuredDebtEstimate,
      0.23,
      5,
      props.availableMonthlyFunds
    );
    const totalPaymentInFiveYears = props.unsecuredDebtEstimate + statusQuoInterestInFiveYears;
    return {
      principalLabel: "Debt",
      principalValue: `$${props.unsecuredDebtEstimate.toLocaleString()}`,
      interestLabel: "Estimated interest after 5 years*",
      interestValue: `+ $${Math.round(statusQuoInterestInFiveYears).toLocaleString()}`,
      totalLabel: "Total payment in 5 years",
      totalValue: `= $${Math.round(totalPaymentInFiveYears).toLocaleString()}`,
      durationLabel: "Time until debt free",
      durationValue: "Never",
    };
  })();

  const rowCountDifference = props.optionInfo.savingsBreakdown.length - 1;
  const emptyStatusQuoRows = Array.from({ length: rowCountDifference }).map(() => null);

  const isProduction = NODE_ENV === "production";

  return (
    <StyledDetailCardContainer>
      <StyledDetailCard isEligible={props.optionInfo.isEligible}>
        <button className="back" onClick={props.onClickBack} data-test-label="back-button">
          Back
        </button>
        <div style={{ marginTop: "-80px" }}>
          <StyledDetailCardThumbContainer>
            {props.optionInfo.isEligible ? (
              <ThumbsUp />
            ) : props.optionInfo.isEligible !== false ? (
              <QuestionMark />
            ) : (
              <ThumbsDown />
            )}
          </StyledDetailCardThumbContainer>
          <div className="title_section section">
            <DetailHeading>{props.optionInfo.title}</DetailHeading>
            <DetailDescriptionCentered>{props.optionInfo.description}</DetailDescriptionCentered>

            {props.optionInfo.isEligible !== false && (
              <FinancialInfoContainer>
                <div className="items">
                  <div className="financial-item">
                    <p>${Math.round(props.optionInfo.paymentInformation.value).toLocaleString()}</p>
                    <strong>{props.optionInfo.paymentInformation.fieldName}</strong>
                  </div>
                  <div className="financial-item">
                    <p>{format(addMonths(new Date(), props.optionInfo.debtTimelineBreakdown.duration), "MMM y")}</p>
                    <strong>Debt-Free Date</strong>
                  </div>
                  <div className="financial-item">
                    <p>{savings}</p>
                    <strong>Savings</strong>
                  </div>
                </div>
              </FinancialInfoContainer>
            )}

            {props.optionInfo.notEligibleMessage && (
              <IneligibleMessageBox>{props.optionInfo.notEligibleMessage}</IneligibleMessageBox>
            )}

            <div className="actions_section">
              <div className="button-container">
                {!isProduction && <AIPromptButton displayText="Ask A Question" onClick={() => chat.setIsOpen(true)} />}
                {props.optionInfo.actions}
                <div className="disclaimer">
                  The estimates above reflect common interest rates, standard timelines, and the debts you’ve shared
                  with us.
                </div>
              </div>
            </div>
          </div>
        </div>

        {props.optionInfo.isEligible === true && (
          <div className="savings_section section">
            <SectionTitle>SAVINGS BREAKDOWN</SectionTitle>
            <SavingsTable>
              <div className="column">
                <div className="header">Current Payments</div>
                <div className="content">
                  <div className="row">
                    <div className="description">
                      <div className="amount">{statusQuoSavingsBreakdownCalculations.principalValue}</div>
                      <div className="label">{statusQuoSavingsBreakdownCalculations.principalLabel}</div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="description">
                      <div className="amount">{statusQuoSavingsBreakdownCalculations.interestValue}</div>
                      <div className="label">{statusQuoSavingsBreakdownCalculations.interestLabel}</div>
                    </div>
                  </div>
                  {emptyStatusQuoRows.map(() => (
                    <EmptyRow />
                  ))}

                  <div className="row total">
                    <div className="description">
                      <div className="amount current">{statusQuoSavingsBreakdownCalculations.totalValue}</div>
                      <div className="label">{statusQuoSavingsBreakdownCalculations.totalLabel}</div>
                    </div>
                  </div>

                  <div className="row timeline">
                    <div className="description">
                      <div className="amount current">{statusQuoSavingsBreakdownCalculations.durationValue}</div>
                      <div className="label">{statusQuoSavingsBreakdownCalculations.durationLabel}</div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="column option-taken">
                <div className="header">{props.optionInfo.optionName}</div>
                <div className="content">
                  <div className="row">
                    <div className="description">
                      <div className="amount">${Math.round(props.unsecuredDebtEstimate).toLocaleString()}</div>
                      <div className="label">Debt</div>
                    </div>
                  </div>

                  {props.optionInfo.savingsBreakdown.map((savingsBreakdownRow) => (
                    <div className="row" key={savingsBreakdownRow.item?.toString()}>
                      <div className="description">
                        <div className="amount">{savingsBreakdownRow.value}</div>
                        <div className="label">{savingsBreakdownRow.item}</div>
                      </div>
                    </div>
                  ))}

                  <div className="row total">
                    <div className="description">
                      <div className="amount option-taken">
                        = ${Math.round(props.optionInfo.totalPayment).toLocaleString()}
                      </div>
                      <div className="label option-taken">Total payment</div>
                    </div>
                  </div>

                  <div className="row timeline">
                    <div className="description">
                      <div className="amount option-taken">
                        {formatMonths(props.optionInfo.debtTimelineBreakdown.duration)}
                      </div>
                      <div className="label option-taken">Time until debt free</div>
                    </div>
                  </div>
                </div>
              </div>
            </SavingsTable>
            <div className="disclaimer">
              *Interest estimated at 23% using the average{" "}
              <a target="_blank" href="https://www.federalreserve.gov/releases/g19/current/">
                credit card interest rate
              </a>{" "}
              in 2024
            </div>

            {!isProduction &&
              props.optionInfo.savingsQuestions.map((question) => (
                <AIPromptButton displayText={question} onClick={() => clickPromptButton(question)} />
              ))}
          </div>
        )}
        {isProduction && (
          <div className="good_for_section section">
            <b className="section-title">Who is it good for?</b>
            <div>{props.optionInfo.goodForStatement}</div>
          </div>
        )}

        {isProduction && (
          <div className="affordability_section section">
            <b className="section-title">
              Can you afford it?{" "}
              {props.optionInfo.canAfford ? "👍" : props.optionInfo.canAfford === undefined ? <></> : "👎"}
            </b>
            <div>{props.optionInfo.canAffordMessage}</div>
          </div>
        )}

        {!isProduction && (
          <div className="good_for_section section">
            <b className="section-title">Common Questions</b>
            {props.optionInfo.faqQuestions.map((faqQuestion) => (
              <AIPromptButton displayText={faqQuestion} onClick={() => clickPromptButton(faqQuestion)} />
            ))}
          </div>
        )}

        <div className="actions_section">
          <div className="button-container-bottom">{props.optionInfo.actions}</div>
        </div>
      </StyledDetailCard>
    </StyledDetailCardContainer>
  );
};
const StyledDetailCardContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: auto;
`;
const StyledDetailCard = styled.div<{ isEligible: boolean | undefined }>`
  display: flex;
  flex-direction: column;
  text-align: center;
  flex: 1;
  padding: 8px;
  max-width: 600px;
  gap: 24px;
  .back {
    color: black;
    background-color: ${theme.colors.white[700]};
    font-size: 14px;
    font-weight: 500;
    border: none;
    border-radius: 12px;
    padding: 10px 24px;
    cursor: pointer;
    width: 20vw;
    max-width: 100px;
    margin-top: 30px;
    z-index: 99;
  }
  .section {
    padding: 32px 24px;
    @media (min-width: ${(props) => props.theme.breakpoints[500]}) {
      padding: 40px;
    }
    background: #ffffff;
    box-shadow: 0px 24px 24px rgba(0, 0, 0, 0.05), 0px 16px 24px rgba(0, 0, 0, 0.05);
    border-radius: 16px;
    text-align: start;
    display: flex;
    flex-direction: column;
    gap: 16px;
    font-size: 14px;
    .section-title {
      text-transform: uppercase;
      opacity: 80%;
      color: ${theme.colors.gray[500]};
      font-weight: 700;
    }
  }
  .title_section {
    border-radius: 8px;
    padding-top: 40px;
    padding-bottom: 24px;
    box-sizing: border-box;
    display: flex;
    gap: 0px;
    flex-direction: column;
    text-align: center;
    .disclaimer {
      font-size: 11px;
      line-height: 150%;
      color: ${theme.colors.gray[700]};
      opacity: 0.7;
    }
  }
  .savings_section {
    display: flex;
    flex-direction: column;
    .estimated_debt {
      display: flex;
      flex-direction: column;
    }
    .title {
      margin-bottom: 8px;
    }
    .payment {
      display: flex;
      flex: 1;
      justify-content: flex-end;
      align-items: center;
    }
    .disclaimer {
      font-size: 11px;
      opacity: 0.7;
    }
  }
  .good_for_section {
  }
  .affordability_section {
  }
  .actions_section {
    width: 100%;
    display: flex;
    flex-direction: column;
    .button-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
    .button-container-bottom {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 8px;
      margin-bottom: 60px;
    }
  }

  .bottom_padding {
    padding: 20px;
  }

  .red {
    color: ${theme.colors.red[300]};
  }
  .green {
    color: ${theme.colors.green[300]};
  }
  .gray {
    color: ${theme.colors.gray[500]};
  }
  .info-row {
    display: flex;
    justify-content: space-between;
  }
  .info-row + .info-row {
    border-top: 1px dashed ${(props) => props.theme.colors.white[300]};
    margin-top: 5px;
    padding-top: 5px;
  }
`;

const StyledDetailCardThumbContainer = styled.div`
  position: relative;
  top: 38px;
`;

const IneligibleMessageBox = styled.div`
  font-size: 14px;
  text-align: start;
  width: 100%;
  margin-bottom: 1em;
  background-color: #f5f5f8;
  border-radius: 8px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  .title {
    text-align: center;
  }
`;

const FinancialInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;

  .items {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 32px;
    gap: 22px;
    width: 100%;
    box-sizing: border-box;
    @media (max-width: 768px) {
      padding: 0;
      gap: 12px;
    }
  }

  .financial-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;

    p {
      font-size: 20px;
      font-weight: bold;
      color: #333;
      margin: 0;

      @media (max-width: 768px) {
        font-size: 16px;
      }
    }

    strong {
      font-size: 12px;
      font-weight: 300;
      color: #666;

      @media (max-width: 768px) {
        margin-top: -4px;
        font-size: 10px;
      }
    }
  }
`;

const SectionTitle = styled.div`
  font-size: 13px;
  font-weight: 500;
  color: #6b7280;
  letter-spacing: 0.05em;
  text-transform: uppercase;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const SavingsTable = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    left: 50%;
    top: 0;
    height: 100%;
    border-left: 1px dashed #e5e7eb;
  }

  .column {
    display: flex;
    flex-direction: column;

    &.option-taken {
      background-color: rgba(235, 239, 255, 0.5);
      border-top-right-radius: 8px;

      .header {
        border-top-right-radius: 8px;
      }
    }
  }

  .header {
    font-size: 14px;
    font-weight: 600;
    color: ${theme.colors.black[300]};
    padding: 16px;
    border-bottom: 1px dashed #e5e7eb;

    @media (max-width: 768px) {
      padding: 12px;
    }
  }

  .content {
    display: flex;
    flex-direction: column;

    .row {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      height: 72px;
      padding: 16px;
      border-bottom: 1px dashed #e5e7eb;
      box-sizing: border-box;

      &:last-child {
        border-bottom: none;
      }

      .amount {
        font-size: 14px;
        font-weight: 500;
        line-height: 1.2;
        margin-bottom: 4px;
        color: black;
      }

      .label {
        padding-top: 3px;
        color: ${theme.colors.black[100]};
        opacity: 0.7;
        font-size: 12px;
        line-height: 1.7;

        @media (max-width: 768px) {
          font-size: 10px;
          line-height: 1.4;
        }
      }

      .description {
        flex: 1;
        padding-right: 16px;

        @media (max-width: 768px) {
          padding-right: 4px;
        }
      }

      &.total {
        .amount {
          &.option-taken {
            color: ${theme.colors.brand[500]};
          }
        }
        .label {
          &.option-taken {
            color: ${theme.colors.brand[500]};
            opacity: 0.7;
          }
        }
      }

      &.timeline {
        .amount {
          &.option-taken {
            color: ${theme.colors.brand[500]};
          }
        }
        .label {
          &.option-taken {
            color: ${theme.colors.brand[500]};
            opacity: 0.7;
          }
        }
      }
    }
  }
`;
