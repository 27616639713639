import styled from "styled-components";
import { ProgressBarWrapper } from "./ProgressBar";
import { Modal, ModalBody, ModalHeader } from "./Modal";
import { P, TypographyCSS } from "@upsolve/ui";
import GradientBackground from "../DebtAdvisor/images/gradient-background.png";

export const FullScreenTriageModal = styled(Modal)`
  background-image: url("${GradientBackground}");
  background-color: white;
  height: 100%;
  width: 100%;
`;

export const TriageModal = styled(Modal)`
  padding: 2em;
  @media (max-width: ${(p) => p.theme.breakpoints[500]}) {
    max-height: initial;
    min-height: initial;
    padding: 1em;
  }

  padding-top: 2em;
  width: 100%;
  max-width: 600px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  position: relative;
`;

//TODO: DEPRECATE
export const OldTriageModalBody = styled(ModalBody)`
  padding: 2em;
  min-height: 350px;
  input:focus,
  select:focus {
    box-shadow: 0 0 5px ${(props) => props.theme.colors.brand["700"]};
  }
  & > div {
    margin: auto;
    width: 100%;
  }
  @media (max-width: ${(p) => p.theme.breakpoints[500]}) {
    flex-grow: 1;
    max-height: initial;
    min-height: initial;
    padding: 0.75em;
  }

  // Content Nodes Styling
  form,
  .content-node__input,
  button,
  input,
  select {
    width: 100%;
  }
  form .content-node__row {
    height: 100%;
    gap: 12px;
    margin-top: 12px;
    & > button {
      max-height: 100%;
    }
  }
  ul {
    list-style-type: disc;
    margin-left: 10px;
  }

  div:has(> form) {
    overflow-y: auto;
  }
`;

export const ModalContent = styled.div`
  input:focus,
  select:focus {
    box-shadow: 0 0 5px ${(props) => props.theme.colors.brand["700"]};
  }

  form,
  button,
  input,
  select {
    width: 100%;
  }
  width: 100%;
`;

export const FullScreenModalContent = styled.div``;
